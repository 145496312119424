import React, { useState } from 'react'
import api from '../../services/api'
import './index.css'

export default function Login({ history }) {
    var [nickname, setNickname] = useState('')
    const [passcode, setPasscode] = useState('')
    const [keepconnected, setKeepconnected] = useState('')
    const [ShowERRROR, ShowTHERO] = useState('hidden')
  
    const showError = (Error) => { if (ShowERRROR !== 'hidden') { return (
      <div className="ErrorMessageAuthPageTitle">
        <h3>&nbsp;&nbsp;Falha no login</h3>
          <div className="ErrorMessageAuthPageInfo">
            <b>&nbsp;&nbsp;</b><b id="ERRORINFOTEXT">{ShowERRROR}</b><p>&nbsp;</p>
          </div>
          <br></br><br></br>
        </div>
      )}}
  
    function handleSubmit(event) {
      event.preventDefault()
      let keepconnecteded;
      if (keepconnected === true) {
        keepconnecteded = "1"
      } else {
        keepconnecteded = "2"
      }
  
      if (localStorage.getItem('UserName') && nickname === "") {nickname = localStorage.getItem('UserName')}
      api.post('/login', "", { headers: {
        AuthId: localStorage.getItem('AuthId'),
        nickname,
        passcode,
        keeplogined: keepconnecteded,
        withCredentials:true,
      }, withCredentials: true, credentials: 'include'}).then(Response => {
        if (Response.data.ErrorMessage) {
          ShowTHERO(Response.data.ErrorMessage)
        }
        if (Response.data.authid) {
          localStorage.setItem('UserName', Response.config.headers.nickname)
          localStorage.setItem('AuthId', Response.data.authid)

          history.push('/dashboard')
        }
      })
    }
    return (
      <div className="PlaceHolder">
        <div className="content" id="blur"></div>
        <div className="content">
          <div className="ContentPlaceHolder">
            <p><b>AUTENTICAÇÃO</b></p>
            {showError()}<br></br>
            <form onSubmit={handleSubmit}>
            <label htmlFor="nickname">&nbsp;&nbsp;Nome de usuario <b className="REDCOLORSTAR">*</b></label>
              <input 
                type="nickname" 
                id="nickname" 
                placeholder="Nome de autenticação do usuario"
                value ={nickname}
                onChange={event => setNickname(event.target.value)}
              />
              <label htmlFor="passcode">&nbsp;&nbsp;Codigo para acesso <b className="REDCOLORSTAR">*</b></label>
              <input 
                type="passcode" 
                id="passcode" 
                placeholder="Codigo de acesso"
                value ={passcode}
                onChange={event => setPasscode(event.target.value)}
              />
              <div className="input-group">
                <input type="checkbox" id="SILVERPLACE" name="Manter-me conectado" value ={false} onChange={event => setKeepconnected(event.target.checked)} hidden />
                <label htmlFor="SILVERPLACE" className="checkbox"><span className="icon"></span><span className="text">Manter-me conectado</span></label>
              </div> 
            <button type="submit" className="btn">Entrar</button>
            </form>
          </div>
        </div>
      </div>
    )
}