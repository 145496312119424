import React, { useEffect, useState } from 'react'

import './index.css'

import image from '../../resources/TalesGardemLogo.png'
import uptime from '../../resources/UptimeIcon.png'
import Performance from '../../resources/High Peformance.png'
import SpaceInterpriseLogo from '../../resources/Space_Interprise_Logo_Transparent_Background.png'
import OpenSource from '../../resources/OpensourceIcon.png'
import aprimorar from '../../resources/Aprimoraricon.png'

export default function MainPage( {history} ) {

    const [HeaderOpacity, SetHeaderOpacity] = useState('0');
    const [TitlePositionAjuster, SetTitlePositionAjuster] = useState('33');
    const [TitleTopAjuster, SetTitleTopAjuster] = useState('35');
    const [TitleFontAjuster, SetTitleFontAjuster] = useState('7');
    const [TitleImageAjuster, SetTitleImageAjuster] = useState('10');
    const [TitleImageAjuster2] = useState('-1');
    const [DiscordAjuster, SetDiscordAjuster] = useState('20');
    const [DiscordAjuster2, SetDiscordAjuster2] = useState('-1');
    const [VerticalWarnSize, SetVerticalWarnSize] = useState(0);
    
    useEffect(() => {
        function ResizeHandler() {
            SetVerticalWarnSize((((window.innerHeight-((window.innerWidth/100)*118))/2)-(document.getElementsByClassName('VerticalWarn')[0].clientHeight/2)));
        }
        const script = document.createElement('script');
        const script2 = document.createElement('script');
        function ScrollHandler() { 
            if (window.scrollY > 250) { 
                SetHeaderOpacity(100);
                SetTitlePositionAjuster(13);
                SetTitleTopAjuster(1.7);
                SetTitleFontAjuster(5.5);
                SetTitleImageAjuster(8);
                SetDiscordAjuster(55);
                SetDiscordAjuster2(-4.5);
                return;
            }
            SetHeaderOpacity(window.scrollY-100)
            SetTitlePositionAjuster(20-((window.scrollY-100)*(13/100)))
            SetTitleTopAjuster(-15-((window.scrollY-100)*(30/100)))
            SetTitleFontAjuster((-10)-((window.scrollY-100)*(17/100)))
            SetTitleImageAjuster((-7)-((window.scrollY-100)*(17/100)))
            SetDiscordAjuster((20)+((window.scrollY-100)*(55/100)))
            if (window.scrollY> 175 || Number(document.getElementsByClassName("DiscordButtonWrapper")[0].style.left.slice(0,document.getElementsByClassName("DiscordButtonWrapper")[0].style.left.length-2)) < -1) {
                SetDiscordAjuster2((-4.5)+((window.scrollY-100)*(-4.5/100)))
            } else {
                SetDiscordAjuster2(1)
            }
            if (window.scrollY > 200) {
                SetTitleFontAjuster(5.5)
                SetTitleImageAjuster(8)
            }
        if (window.scrollY < 100) {
            SetHeaderOpacity(0)
        }
        if (Number(document.getElementsByClassName("TitleWrapper")[0].style.left.slice(0,document.getElementsByClassName("TitleWrapper")[0].style.left.length-2)) < 13) {
            SetTitlePositionAjuster(13)
        }
        if (Number(document.getElementsByClassName("TitleWrapper")[0].style.marginTop.slice(0,document.getElementsByClassName("TitleWrapper")[0].style.marginTop.length-2)) < 1.7) {
            SetTitleTopAjuster(1.7)
        }
        if (Number(document.getElementsByClassName("TitleWrapper")[0].style.fontSize.slice(0,document.getElementsByClassName("TitleWrapper")[0].style.fontSize.length-2)) < 5.5) {
            SetTitleFontAjuster(5.5)
        }
        if (Number(document.getElementsByClassName("TalesGardemLogoWrapper")[0].style.width.slice(0,document.getElementsByClassName("TalesGardemLogoWrapper")[0].style.width.length-2)) < 8) {
            SetTitleImageAjuster(8)
        }
        if (Number(document.getElementsByClassName("DiscordButtonWrapper")[0].style.marginTop.slice(0,document.getElementsByClassName("DiscordButtonWrapper")[0].style.marginTop.length-2)) < -4.5) {
            SetDiscordAjuster2(-4.5)
        }
        if (Number(document.getElementsByClassName("DiscordButtonWrapper")[0].style.marginTop.slice(0,document.getElementsByClassName("DiscordButtonWrapper")[0].style.marginTop.length-2)) > -1) {
            SetDiscordAjuster2(-1)
        }
        if (Number(document.getElementsByClassName("DiscordButtonWrapper")[0].style.left.slice(0,document.getElementsByClassName("DiscordButtonWrapper")[0].style.left.length-2)) > 55) {
            SetDiscordAjuster(55)
        }
        if (Number(document.getElementsByClassName("DiscordButtonWrapper")[0].style.left.slice(0,document.getElementsByClassName("DiscordButtonWrapper")[0].style.left.length-2)) < 20) {
            SetDiscordAjuster(20)
        }
        if (Number(document.getElementsByClassName("DiscordButtonWrapper")[0].style.left.slice(0,document.getElementsByClassName("DiscordButtonWrapper")[0].style.left.length-2)) === -35) {
            SetDiscordAjuster(20)
        }
    }
        script.src = "https://vincentgarreau.com/particles.js/assets/_build/js/lib/particles.js";
        script2.text = `function tryer() { try{ window.particlesJS("particles-js", {
        particles: {
            number: {
            value: 100,
            density: {
                enable: true,
                value_area: 789.1476416322727
            }
            },
            color: {
            value: "#120020"
            },
            shape: {
            type: "circle",
            stroke: {
                width: 0,
                color: "#000000"
            },
            polygon: {
                nb_sides: 5
            },
            image: {
                src: "img/github.svg",
                width: 100,
                height: 100
            }
            },
            opacity: {
            value: 0.78927153781200905,
            random: false,
            anim: {
                enable: true,
                speed: 0.2,
                opacity_min: 0,
                sync: false
            }
            },
            size: {
            value: 3,
            random: true,
            anim: {
                enable: true,
                speed: 2,
                size_min: 0,
                sync: false
            }
            },
            line_linked: {
            enable: false,
            distance: 150,
            color: "#ffffff",
            opacity: 0.4,
            width: 1
            },
            move: {
            enable: true,
            speed: 5,
            direction: "top",
            random: true,
            straight: true,
            out_mode: "out",
            bounce: true,
            attract: {
                enable: false,
                rotateX: 600,
                rotateY: 1200
            }
            }
        },
        interactivity: {
            detect_on: "canvas",
            events: {
            onhover: {
                enable: false,
                mode: "bubble"
            },
            onclick: {
                enable: false,
                mode: "push"
            },
            resize: true
            },
            modes: {
            grab: {
                distance: 400,
                line_linked: {
                opacity: 1
                }
            },
            bubble: {
                distance: 83.91608391608392,
                size: 1,
                duration: 3,
                opacity: 1,
                speed: 3
            },
            repulse: {
                distance: 200,
                duration: 0.4
            },
            push: {
                particles_nb: 4
            },
            remove: {
                particles_nb: 2
            }
            }
        },
        retina_detect: false
    })} catch (e) {setTimeout(()=>{tryer()}, 5000)}}; tryer()`
        script.async = true;
        script2.async = true;
        document.body.appendChild(script);
        document.body.appendChild(script2);
        window.addEventListener('scroll', ScrollHandler)
        window.addEventListener('resize', ResizeHandler)
        ScrollHandler();
        ResizeHandler()
    }, [VerticalWarnSize]);
    return (
        <>
            <img className="ImageBackgroundPageWrapper" alt="" src="https://weaver.com//sites/default/files/2019-04/68835659_xl%20server%20room%20-%20onpage-01.jpg"/>
            <div className="ContentWrapper">
                <div className="HeaderContainer" style={{opacity: HeaderOpacity+"%"}}>

                </div>
                <h1 className="TitleWrapper" style={{left: TitlePositionAjuster+"vw", marginTop: TitleTopAjuster+"vw", fontSize: TitleFontAjuster+"vw"}}>
                    <img className="TalesGardemLogoWrapper" alt="" style={{width: TitleImageAjuster+"vw", marginTop: TitleImageAjuster2+"vw"}} src={image}/>
                    TalesGardem
                    <br/>
                    <button className="DiscordButtonWrapper" style={{marginTop: DiscordAjuster2+"vw",left: DiscordAjuster+"vw"}} onClick={()=>{window.location.replace("https://discord.com/api/oauth2/authorize?client_id=678686006848913411&permissions=536870743&scope=bot")}}>
                        <img className="DiscordLogoWrapper" alt="" src="https://discord.com/assets/28174a34e77bb5e5310ced9f95cb480b.png"/>
                        &nbsp;&nbsp;&nbsp;&nbsp;Adicionar ao discord
                    </button>
                </h1>
                <div className="FaderWrapper"></div>
                <div id="particles-js" className="ParticlesWrapper"></div>
                <div className="Part2Wrapper">
                    <div className="CardsWrapper">
                        <div className="Card">
                            <div className="TopImageWrapper">
                                <img className="TopElementImage" alt="" src={uptime}/>
                            </div>
                            <p className="Text">O TalesGardem fica ligado 99,9% do tempo para garantir uma boa experiência ao seu servidor.</p>
                        </div>
                        <div className="Card">
                            <div className="TopImageWrapper">
                                <img className="TopElementImage" alt="" src={Performance}/>
                            </div>
                            <p className="Text">O TalesGardem é mantido nas melhores hosts para garantir a melhor performance para seu servidor.</p>
                        </div>
                        <div className="Card">
                            <div className="TopImageWrapper">
                                <img className="TopElementImage" alt="" src={OpenSource}/>
                            </div>
                            <p className="Text">O TalesGardem é OpenSource e seu codígo pode ser encontrado no GitHub: <a href="https://github.com/emanuelfranklyn/Tales_Gardem/">https://github.com/emanuelfranklyn/Tales_Gardem/</a></p>
                        </div>
                        <div className="Card">
                            <div className="TopImageWrapper">
                                <img className="TopElementImage" alt="" src={aprimorar}/>
                            </div>
                            <p className="Text">O TalesGardem está em constante aprimoramento e aperfeiçoamento para uma melhor experiência.</p>
                        </div>
                    </div>
                </div>
                <div className="EndHeaderWrapper">
                    <b className="CreatedByWrapper">Criado por:</b>
                    <img className="Space_InterpriseLogoWrapper" alt="" src={SpaceInterpriseLogo}/>
                    <p className="Space_InterpriseTextWrapper">Space_Interprise</p>
                    <p className="ServerRoomCopyright">Imagem de servidores:<br/><a href="https://www.weaver.com">https://www.weaver.com</a><br/>Gerador de particulas:<br/><a href="https://vincentgarreau.com/particles.js">https://vincentgarreau.com/particles.js</a></p> 
                </div>
                <div className="EndOfPage">
                    <h1 className="VerticalWarn" style={{marginTop: VerticalWarnSize+'px'}}>Deite o seu dispositivo para uma melhor experiência.</h1>
                </div>
            </div>
        </>
    )
}