import React, { useEffect } from 'react'
import './index.css'
import image from '../../resources/NotFounded404.png'

export default function NoMatch( {history} ) {
    useEffect(() => {
        const script = document.createElement('script');
        const script2 = document.createElement('script');
      
        script.src = "https://vincentgarreau.com/particles.js/assets/_build/js/lib/particles.js";
        script2.text = `function tryer() { try{ window.particlesJS("particles-js", {
        particles: {
            number: {
            value: 450,
            density: {
                enable: true,
                value_area: 789.1476416322727
            }
            },
            color: {
            value: "#EECCFF"
            },
            shape: {
            type: "circle",
            stroke: {
                width: 0,
                color: "#000000"
            },
            polygon: {
                nb_sides: 5
            },
            image: {
                src: "img/github.svg",
                width: 100,
                height: 100
            }
            },
            opacity: {
            value: 0.48927153781200905,
            random: false,
            anim: {
                enable: true,
                speed: 0.2,
                opacity_min: 0,
                sync: false
            }
            },
            size: {
            value: 3,
            random: true,
            anim: {
                enable: true,
                speed: 2,
                size_min: 0,
                sync: false
            }
            },
            line_linked: {
            enable: false,
            distance: 150,
            color: "#ffffff",
            opacity: 0.4,
            width: 1
            },
            move: {
            enable: true,
            speed: 1,
            direction: "top",
            random: true,
            straight: false,
            out_mode: "out",
            bounce: false,
            attract: {
                enable: false,
                rotateX: 600,
                rotateY: 1200
            }
            }
        },
        interactivity: {
            detect_on: "canvas",
            events: {
            onhover: {
                enable: false,
                mode: "bubble"
            },
            onclick: {
                enable: false,
                mode: "push"
            },
            resize: true
            },
            modes: {
            grab: {
                distance: 400,
                line_linked: {
                opacity: 1
                }
            },
            bubble: {
                distance: 83.91608391608392,
                size: 1,
                duration: 3,
                opacity: 1,
                speed: 3
            },
            repulse: {
                distance: 200,
                duration: 0.4
            },
            push: {
                particles_nb: 4
            },
            remove: {
                particles_nb: 2
            }
            }
        },
        retina_detect: false
    })} catch (e) {setTimeout(()=>{tryer()}, 5000)}}; tryer()`
        script.async = true;
        script2.async = true;
        document.body.appendChild(script);
        document.body.appendChild(script2);
        var mouseX = 0, 
        mouseY = 0,
        follower = document.getElementById("particles-js"),
        xp = 0,
        yp = 0;

        document.onmousemove = function(e){
            mouseX = e.pageX;
            mouseY = e.pageY; 
        };

        function animate(){
            requestAnimationFrame(animate);
            xp += (mouseX - xp) / 50;
            yp += (mouseY - yp) / 50;
            follower.style.left = ((window.innerWidth/2)*-1 +xp) + 'px';
            follower.style.top= ((window.innerHeight/2) - yp) + 'px';
        }

        animate();
    }, []);

    function gohome(e) {
        e.preventDefault();
        history.push("/");
    }

    return (
        <div className="PageWrapper">
            <h1 className="h404">404</h1>
            <div className="Phrases">
                <p className="phrase0">Onde é que eu estou?</p>
                <p className="phrase1">Será que eu estou na lagoinha?</p>
                <p className="phrase2">Num campo deserto?</p>
                <p className="phrase3">Eu estou sem saber onde é que eu estou!</p>
                <button className="HomePageButton" onClick={gohome}>Inicio</button>
            </div>
            <img className="BorderImage" alt="" src={image}></img>
            <div id="particles-js"/>
        </div>
    )
}