import React from "react";
import { slide as Menu } from "react-burger-menu";

export default props => {
  return (
    <Menu {...props}>
      <a className="menu-item" href="/dashboard">
        Home
      </a>

      <a className="menu-item" href="/Bots">
        Bots
      </a>

      <a className="menu-item" href="/Configs">
        Configs
      </a>

      <a className="menu-item" href="/login">
        Logout
      </a>
    </Menu>
  );
};
