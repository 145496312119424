import React, { useEffect, useState } from 'react'
import api from '../../services/api.js'
import './index.css'
import SideBar from "./sidebar";


export default function Dashboard( {history} ) {
    const [botscurrent, setbotscurrent] = useState('Carregando...')
    
    var hexDecode = function(text){
        var j;
        var hexes = text.match(/.{1,4}/g) || [];
        var back = "";
        for(j = 0; j<hexes.length; j++) {
            back += String.fromCharCode(parseInt(hexes[j], 16));
        }
    
        return back;
    }
    useEffect(() => {
        function getbotsstates () {
            api.get('/getState', {
                headers: { 
                    nickname: localStorage.getItem('UserName'), 
                    AuthId: localStorage.getItem('AuthId')
                }
            }).then(response => {
                if (response.data.state) {
                    setbotscurrent(<b style={{ color: hexDecode(response.data.color)}}>{response.data.state}</b>)
                }
            })
        }
        if (localStorage.getItem('AuthId')) {
            async function verifyer() {
                const AutoLogin = await api.post('/login', "", {
                    headers: { nickname: localStorage.getItem('UserName'), AuthId: localStorage.getItem('AuthId')}
                })
                if (!AutoLogin.data.authid) {
                    history.push('/login')
                } else {
                    getbotsstates()
                    setInterval(() => getbotsstates(), 60000);
                }
            }
            verifyer()
        }
        
    }, [history])
    return (
        <>
            <div id="MAIN">
                <div id="App">
                    <p></p>
                    <SideBar pageWrapId={"page-wrap"} outerContainerId={"App"}/>
                </div>
                <div className="MainDashBoardPage">
                    <h1>DashBoard in progress...</h1>
                    <h1>Current State: {botscurrent}</h1>
                </div>
            </div>
        </>
    )
}